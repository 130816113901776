const token = "4bf0dd40b4e505ebecdbd225a9d8c6"

export default async function getVideos(channelId, locale, pageSkip) {
  var query = `query AllVideosQuery($channelId: ItemId!, $locale: SiteLocale!, $pageSkip : IntType!) {
    allVideos(
      skip: $pageSkip
      first: 20,
      orderBy: [_firstPublishedAt_DESC], 
      filter: {
        slug: {neq: null},
        channel: { eq: $channelId } 
      },
      locale: $locale
    ) {
        title
        id
        slug
        duration
        _firstPublishedAt
        thumbnail {
          responsiveImage {
            srcSet           
            webpSrcSet       
            sizes            
            src              
            width             
            height            
            aspectRatio       
            alt               
            title             
            bgColor           
            base64 
          }
        }
        category {
          id
          title
          channel {
            id
            pageLink {
              id
              title
              slug
            }
          }  
        }
    }
  }`

  return fetch("https://graphql.datocms.com/", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      query,
      variables: { channelId, locale, pageSkip },
    }),
  })
    .then(res => res.json())
    .then(res => {
      // console.log("res",res)
      return res.data.allVideos
    })
    .catch(error => {
      console.log(error)
    })
}
