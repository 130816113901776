import React, { useEffect, useState } from "react"
import { graphql } from "gatsby"
import { Box, Container, Grid, Heading, Flex } from "@theme-ui/components"
import Layout from "../components/layout"
import { getLatestVideoPage } from "../utils/path"
import { HelmetDatoCms } from "gatsby-source-datocms"
import VideoThumb from "../components/videoThumb.js"
import { useFavicon } from "../hooks/useFavicon"
import BannerList from "../components/blocks/bannerList"
import Breadcrumbs from "../components/breadcrumbs"
import { i18nContext } from "../context/i18nContext"
import getVideos from "../hooks/getVideos"
import LoadingSpinner from "../components/loadingSpinner"

const Page = ({ data: { site, videosQuery, channel }, pageContext }) => {
  const [hasMore, setHasMore] = useState()
  const [pageSkip, setPageSkip] = useState(0)
  const [videos, setVideos] = useState([])
  const [chunkedVideos, setChunkedVideos] = useState([])
  const [loading, setLoading] = useState(true)
  const favicon = useFavicon().site.faviconMetaTags

  const handleGetVideos = async () => {
    setLoading(true)
    if (chunkedVideos.length >= videosQuery.nodes.length / 8) {
      setLoading(false)
      setHasMore(false)
      return
    }
    setHasMore(true)

    const allVideos = await getVideos(
      channel.nodes[0].id
        .replace("DatoCmsChannel-", "")
        .replace(`-${pageContext.locale}`, ""),
      pageContext.locale,
      pageSkip
    )

    // console.log(allVideos.length)

    if (allVideos) {
      let localedAllVideos = allVideos.map(item => {
        return {
          ...item,
          locale: pageContext.locale,
          meta: {
            firstPublishedAt: item._firstPublishedAt,
          },
        }
      })

      setChunkedVideos(sliceIntoChunks([...videos, ...localedAllVideos], 8))
      setVideos([...videos, ...localedAllVideos])

      setPageSkip(pageSkip + 20)
      setLoading(false)
    }
    setLoading(false)
  }

  useEffect(() => {
    handleGetVideos()
  }, [])

  let tmpi18nPaths = []
  site.locales.map(locale => {
    if (
      (locale !== "de" &&
        locale !== "fr" &&
        pageContext.channel === "cogeneration-channel") ||
      pageContext.channel != "cogeneration-channel"
    )
      tmpi18nPaths.push({
        locale: locale,
        value: getLatestVideoPage(pageContext.channel, locale),
        menuType: pageContext.channel,
      })
  })

  const i18nPaths = tmpi18nPaths

  const sliceIntoChunks = (arr, chunkSize) => {
    const res = []
    for (let i = 0; i < arr.length; i += chunkSize) {
      const chunk = arr.slice(i, i + chunkSize)
      res.push(chunk)
    }
    return res
  }

  return (
    <Layout
      pageSlug={pageContext.channel}
      locale={pageContext.locale}
      i18nPaths={i18nPaths}
    >
      <HelmetDatoCms seo={site.seoMetaTags} favicon={favicon}>
        <html lang={site.locale} />
      </HelmetDatoCms>

      <Box>
        <i18nContext.Consumer>
          {t => (
            <Container>
              <Box sx={{ py: [4, 4, 6], pt: [10, 10, 6] }}>
                <Breadcrumbs
                  page={{
                    model: {
                      apiKey: "latest_videos",
                    },
                    locale: pageContext.locale,
                    title: t[pageContext.title],
                  }}
                />
              </Box>
              <Box sx={{ pt: [0], pb: [0, 0, 6], textAlign: "left" }}>
                <Heading
                  as="h1"
                  variant="h2"
                  sx={{
                    fontWeight: "400",
                    fontSize: [6, 6, 8],
                    lineHeight: "1",
                    color: "primary",
                  }}
                >
                  {t[pageContext.title]}
                </Heading>
              </Box>
              <Box>
                {chunkedVideos &&
                  chunkedVideos.map(videosArray => {
                    return (
                      <>
                        <Grid
                          columns={[1, 2, 3, 4]}
                          gap={["50px 8px", "50px 8px", "50px 8px", "50px 8px"]}
                        >
                          {videosArray.map(video => (
                            <>
                              <VideoThumb
                                search={true}
                                video={video}
                                category={video.title}
                              />
                            </>
                          ))}
                        </Grid>
                        {channel &&
                          channel.nodes[0] &&
                          channel.nodes[0].categoryBanners && (
                            <BannerList
                              banners={channel.nodes[0].categoryBanners}
                              channel={pageContext.channel}
                            />
                          )}
                      </>
                    )
                  })}
              </Box>
              <Flex
                sx={{
                  alignItems: "center",
                  justifyContent: "center",
                  py: [4, 6],
                  mb: [4, 6],
                  position: "relative",
                }}
              >
                {loading && <LoadingSpinner />}
                {hasMore && !loading && (
                  <Box
                    as="button"
                    disabled={!hasMore}
                    onClick={handleGetVideos}
                    variant="links.badge.full"
                    sx={{
                      cursor: "pointer",
                    }}
                  >
                    Show more
                  </Box>
                )}
              </Flex>
            </Container>
          )}
        </i18nContext.Consumer>
      </Box>
    </Layout>
  )
}

export default Page

export const query = graphql`
  query LatestVideosQuery($locale: String!, $channel: String!) {
    site: datoCmsSite {
      locales
    }
    videosQuery: allDatoCmsVideo(
      filter: {
        locale: { eq: $locale }
        slug: { ne: null }
        channel: { pageLink: { slug: { eq: $channel } } }
      }
      sort: { fields: meta___firstPublishedAt, order: DESC }
    ) {
      nodes {
        id
      }
    }
    channel: allDatoCmsChannel(
      filter: { locale: { eq: $locale }, pageLink: { slug: { eq: $channel } } }
    ) {
      nodes {
        id
        pageLink {
          id
          locale
          slug
          topBannerDescription
        }
        categoryBanners {
          id
          title
          image {
            gatsbyImageData(width: 1480, placeholder: BLURRED)
          }
          url
        }
      }
    }
  }
`
