import React from "react"
import { Box } from "theme-ui"
import Circle from "../images/circle.inline.svg"
import { keyframes } from '@emotion/react'

const rotate = keyframes({ from: { transform: "rotate(0deg)" }, to: { transform: "rotate(360deg)"  } })

export default function LoadingSpinner(props) {
  return (
    <Box
      {...props}
      sx={{
        position:"absolute",
        top:"50%",
        left:"50%",
        transform:"translate(-50%,-50%)",
        svg: {
          animation: `${rotate} 1s linear infinite`,
          height: "64px",
          width: "auto",
          "#circle-logo-g": {
            fill: "primary",
          },
        },
      }}
    >
      <Circle />
    </Box>
  )
}
